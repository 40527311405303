import React, { useState } from 'react';
import SEO from '../../components/SEO';

import imageIllustrationMortageFirstSection from '../../assets/images/illustrations/RemortgagePage/variable-mortgage-first.png';
import imageIllustrationMortageFirstSectionMobile from '../../assets/images/illustrations/RemortgagePage/remortgage-first-section-mobile.png';
import imageIllustrationStepFirst from '../../assets/images/illustrations/RemortgagePage/step-image-first.png';
import imageIllustrationStepSecond from '../../assets/images/illustrations/RemortgagePage/step-image-second.png';
import imageIllustrationStepThird from '../../assets/images/illustrations/RemortgagePage/step-image-third.png';
import imageTestimonialsIndi1x from '../../assets/images/illustrations/indi-testimonials1x.png'
import imageTestimonialsIndi2x from '../../assets/images/illustrations/indi-testimonials2x.png'
import imageTestimonialsJenny1x from '../../assets/images/illustrations/jenny-testimonials1x.png'
import imageTestimonialsJenny2x from '../../assets/images/illustrations/jenny-testimonials2x.png'
import imageTestimonialsBrijesh1x from '../../assets/images/illustrations/brijesh-testimonials1x.png'
import imageTestimonialsBrijesh2x from '../../assets/images/illustrations/brijesh-testimonials2x.png'
import imageTestimonialsBella1x from '../../assets/images/illustrations/bella-testimonials1x.png'
import imageTestimonialsBella2x from '../../assets/images/illustrations/bella-testimonials2x.png'


import imageSecondSliderFirst from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderSecond from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderThird from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderFourth from "../../assets/images/illustrations/home-save-money-banner.png"

import imageSecondSliderFirstMobile from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderSecondMobile from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderThirdMobile from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderFourthMobile from "../../assets/images/illustrations/home-save-money-banner.png"



import CustomHeadingSection from '../../components/CommonSections/CustomHeadingSection';
import SectionWrapper from '../../components/CommonSections/SectionWrapper';
import useWindow from '../../hooks/useWindow';
import StepsSection from '../../components/CommonSections/StepsSection';
import FAQSection from '../../components/FAQSection';
import HeaderLeftContent from '../../components/CommonSections/CustomHeadingSection/HeaderLeftContent';
import { Link } from 'gatsby';
import useSliderHeight from '../../hooks/useSliderHeight';
import CustomImage from '../../components/Image/Image';
import { useMemo } from 'react';
import loadable from '@loadable/component';

const ModalsFlow = loadable(() => import("../../components/Modals/ModalsFlow"))
const MortgageDetailsSection = loadable(() => import("../../components/CommonSections/MortgageDetails"))
const FullSizeSliderSectionMid = loadable(() => import("../../components/CommonSections/FullSizeSliderSectionMid"))
const SliderSection = loadable(() => import("../../components/CommonSections/SliderSection"))
const DownloadAppSection = loadable(() => import("../../components/CommonSections/DownloadAppSection"))
const ContactWidget = loadable(() => import("../../components/ContactWidget"))

const IS_MOBILE = () => (typeof window !== 'undefined' ? window.innerWidth <= 767.98 : 0);
const IS_TABLET = () => (typeof window !== 'undefined' ? window.innerWidth <= 991.98 : 0);





const FAQItems = [
  {
    title: 'Is an early repayment charge worth paying?',
    description: (
      <>
        This depends on your specific circumstances and financial goals:<br/><br/>
        <ol>
        <li>Financial flexibility: Seeing whether the benefits of being mortgage-free sooner outweigh the cost of ERC.</li>
        <li>Future plans: If you plan to move or refinance your mortgage in the near future, paying off the loan early might not be worth it.</li>
        <li>Savings on interest: Having a high-interest rate on your mortgage, hence paying off your loan early could save you a significant amount in interest over the long term. By calculating the potential interest saving vs the ERC, you can determine if the savings outweigh the cost of the charge.</li>
        <li>Fixed-rate period: How far along you are on your mortgage term.</li>
        <li>If you remortgage through the Sprive app, we'll be able to do the maths for you and alert you if it's worth you paying an early repayment charge and exiting your deal early to a lower interest deal, should interest rates fall.</li>
        </ol>
      </>
    ),
  },
  {
    title: 'When is the best time to have a fixed mortgage rate?',
    description: (
      <>
      The best time to have a fixed mortgage rate depends on various factors, including current market conditions, your financial goals, and your risk tolerance.<br/><br/>
      Current interest rate: A fixed mortgage deal is generally recommended when base rates are low or expected to rise, as it provides protection against future interest rate increases and ensures that your monthly payments remain stable.<br/><br/>
      Risk Aversion: If you prefer not to take risks, this is a safe bet for you as there is stability with monthly payments.<br/><br/>
      Home plans: If you're planning on staying in your home for a long time, a fixed-rate mortgage may be a good idea as it can provide you with peace of mind, as you don't need to be concerned with refinancing.
      </>
    ),
  },
  {
    title: 'What if I haven’t paid off my mortgage at the end of the fixed rate period?',
    description: (
      <>
        If you haven't paid off your mortgage at the end of your fixed period, your mortgage will typically revert to a standard variable rate (SVR), or you will be given the option to switch mortgage agreements. Remember, the SVR tends to be more expensive.
      </>
    ),
  },
  {
    title: 'Am I secure with a fixed-rate mortgage?',
    description: (
      <>
        Yes, you are guaranteed to not face any change in interest rates whatsoever during your fixed rate term. If you are worried, a shorter-term fixed-rate mortgage may be advisable as this enables borrowers to easily budget around their monthly payments. You can do this whilst being secure in the knowledge that you are protected from any unexpected interest rate rises.
      </>
    ),
  },
  {
    title: 'Is it difficult to get a fixed-rate mortgage?',
    description: (
      <>
        The technicalities of it aren't difficult as they do not vary too much from lender to lender. It could potentially be difficult to qualify for a mortgage term when interest rates are high as in turn monthly payments are higher.<br/><br/>
        However, during periods of higher interest rates, borrowers tend not to opt for a fixed-rate mortgage. Hence, overall, the difficulty is based on your financial circumstances and the base rate.
      </>
    ),
  },
  {
    title: 'Is there any flexibility with my fixed-rate mortgage?',
    description: (
      <>
        Generally, fixed-rate mortgages come with less flexibility compared to variable-rate mortgages. However, there are some circumstances where you may be able to negotiate some flexibility but this may incur extra costs. Such as Early Repayment Options should you want to make extra payments or pay off the mortgage early without incurring prepayment penalties.<br/><br/>
        If this does not appeal to you, there are various other mortgage types which could be better suited for you such as a flexible mortgage, tracker mortgage or discount mortgage.
      </>
    ),
  },
  {
    title: 'How does a fixed-rate mortgage differ from a variable-rate mortgage?',
    description: (
      <>
        Unlike a fixed-rate mortgage, a variable-rate mortgage has an interest rate that can fluctuate throughout the loan term, depending on the changes in the market and the base rate. With a fixed-rate mortgage, you are protected from interest rate rises during the fixed period.
      </>
    ),
  },
  {
    title: 'What is the typical fixed rate term in the UK?',
    description: (
      <>
      In the UK, fixed-rate mortgage terms are commonly available for 2, 3, 5, and 10 years. However, some lenders might offer other options, such as 1-year or longer terms.
      </>
    ),
  },
  {
    title: 'Can I make overpayments on a fixed-rate mortgage?',
    description: (
      <>
      Most lenders allow overpayments on fixed-rate mortgages, however, there might be limits on the amount you can overpay each year without incurring any penalties, the limit is usually 10%. You will need to check with your lender for specific terms and conditions.
      </>
    ),
  },
  {
    title: 'Can I remortgage before the fixed-rate term ends?',
    description: (
      <>
      Yes, you can remortgage before the fixed-rate term expires. However, there will likely be early repayment charges or exit fees involved, so it's important to assess the costs and benefits before making a decision and make sure you know what the terms are with your lender.
      </>
    ),
  },
  {
    title: 'Can I transfer my fixed-rate mortgage to a new property?',
    description: (
      <>
      In most cases, you should be able to transfer your fixed-rate mortgage to a new property. However, this is subject to lender approval and may come with certain charges and conditions so it's important to check with your lender terms.
      </>
    ),
  },
];

function FixedRatePage() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [dealsContactModal, setDealsContactModal] = useState(true)
  const { isDesktop, isMobile } = useWindow();
  const [isTablet, setTablet] = useState(false);
  const sliderHeight = useSliderHeight("611px", "198px", "234px")

  const secondSliderItems = useMemo(() => ([
    {
      image: (isMobile || isTablet) ? imageSecondSliderFirstMobile : imageSecondSliderFirst,
      title: "All you need to know about fixed-rate mortgages?",
      firstDescription: "What are fixed-rate mortgages?",
      secondDescription: (
        <>
        A fixed-rate mortgage means that the interest rate stays the same for the duration of the initial rate period.<br /><br />
        This means that the interest rate you initially receive at the time of taking out the mortgage will stay the same throughout the initial rate period regardless of any changes in the Bank of England's base rate. This is the opposite of a variable-rate mortgage.<br /><br />
        Fixed-rate mortgages are often preferred during periods of low interest rates, as they lock in favorable interest rates. However, it's essential to consider the length of the fixed term that you're committing to. Fixed-rate mortgages are preferred for those who seek certainty and protection against economic uncertainties.
        </>
      )
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderSecondMobile : imageSecondSliderSecond,
      title: "What are the pros and cons of a fixed mortgage?",
      secondDescription: (
        <>
          <b>Pros:</b><br /><br />
          With a fixed-rate mortgage, your monthly mortgage payments will remain consistent for the whole initial rate period, which helps make other financial decisions and budgeting.<br /><br />
          You can benefit from capitalising on low interest rates; locking in a cheaper interest rate for your monthly payments.<br /><br />
          They are easy to understand, compared to some other more complex mortgage terms and conditions.<br /><br />
          A fixed-rate mortgage can offer some protection from inflation and economic uncertainties, as being on a fixed rate means your monthly payments will not be affected by any Bank of England base rate change.
          <b>Cons:</b> <br /><br />
          Fixed-rate mortgages tend to have higher interest rates than adjustable-rate loans as they offer more security.<br /><br />
          You'll miss out if the England base rate falls during your fixed rate period, putting you at a disadvantage with your offered mortgage rates.<br /><br />
          If you want to switch lenders, this could incur an early repayment charge (ERC).<br /><br />
          There is less flexibility with a fixed-rate mortgage as you're locked in until that fixed-rate period ends. which means you may miss out on potential savings if interest rates drop.
        </>
      )
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderThirdMobile : imageSecondSliderThird,
      title:"What is the best mortgage term for a fixed mortgage?",
      firstDescription: "The fixed-rate period always depends on your circumstances and your financial stability. For some, provided the interest rate is low, a longer period of time is preferable if it locks you into a lower rate.",
      secondDescription: (
        <>
        This mortgage deal can be fixed for several years (the most common mortgage terms are 2-year, 3-year and 5-year). Fixing your mortgage for 2 years can give you certainty and stability in the short term, allowing for certainty with finances around monthly payments.<br /><br />
        On the other hand, fixing your mortgage for a 5-year fixed or above, allows you to budget and make other financial decisions with stability.<br /><br />
        The choice of term depends on your financial goals, risk tolerance, and expectations regarding interest rates.
        </>
      ),
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderFourthMobile : imageSecondSliderFourth,
      title: "How do I find the best fixed-rate mortgage deal?",
      firstDescription: "Finding the best fixed-rate mortgage deal involves some research and comparing the market. It's important to assess your financial situation first, including your income, loan to value (LTV), expenses and credit score.",
      secondDescription: (
        <>
        The best thing to do, especially if you are a first-time buyer, is to speak to a few lenders and compare the offerings and your options. Important factors to take into consideration are the base rate, the offered interest rate, term length, associated fees and other technical terms and conditions. Consulting with a mortgage broker could also be beneficial.<br /><br />
        A mortgage broker is a person or company that would assist in navigating the mortgage market and weighing out your options and hence arranging a mortgage between you (the borrower) and a lender<br /><br />
        At Sprive, we are able to assist you in making the right financial decision which caters to your personal circumstance. In just a few clicks we're able to provide you with the latest deals by scanning the market across 90+ lenders. All you need to do is provide us with a few details about your mortgage and property. Or by using the Sprive app we scan the market every day and compare your existing deal against the market to find your next deal.
        </>
      )
    },
  ]), [isMobile, isTablet]);


  return (
    <>
      <SEO title="Remortgage Page" />
      <div className="common-page remortgage-page">
        <div className="remortgage-page__heading-container">
          <SectionWrapper isFirstSection={true}>
            <CustomHeadingSection
              leftContent={
                <HeaderLeftContent
                  title={
                    <>
                      What is a fixed-rate
                      {isDesktop ? <br /> : ' '}
                      mortgage?
                    </>
                  }
                  image={isMobile ? imageIllustrationMortageFirstSectionMobile : null}
                  imageReversed
                  description="In just a few clicks we can help you find personalised variable rate mortgage deals across the market."
                  buttonText="Compare Deals"
                  hashUrlOnClick="/mortgages/remortgage#mortgage-details"
                  useWidget
                />
              }
            />
            {!isMobile && (
              <div className="main-image-container">
                <CustomImage
                    className="w-100 main-image"
                    src={imageIllustrationMortageFirstSection}
                    alt="main image"
                  />
              </div>
            )}
          </SectionWrapper>
        </div>
        <div style={{minWidth: "100vw", minHeight: isMobile ? "826px" : "1000px"}}>
          <FullSizeSliderSectionMid
            sliderItems={secondSliderItems}
          />
        </div>
        <FAQSection
          FAQItems={FAQItems}
          title={
            <>
              Have a question about fixed-rate mortgages?
              {isDesktop ? <br /> : ' '}
              Check out our FAQ’s
            </>
          }
        />
        <div style={{minHeight: isMobile ? "382px" : "429px"}}>
          <DownloadAppSection />
        </div>
        <ContactWidget
          handleClick={() => {
            setIsContactModalOpen(true)
            setDealsContactModal(false)
          }}
          title="Speak to a mortgage expert for FREE"
        />
        <ModalsFlow
          isFirstOpened={isContactModalOpen}
          setIsFirstOpened={(state) => setIsContactModalOpen(state)}
          dealsContactModal={dealsContactModal}
        />
      </div>
    </>
  );
}



export default FixedRatePage;
